import ERRORS from "./errors";
import PATHS from "./paths";

export { ERRORS, PATHS };

export * from "./cancellation-reasons";
export * from "./carTypesExtra";
export * from "./feature-toggles";
export * from "./filters";
export * from "./intlMessages";
export * from "./keycodes";
export * from "./user-groups";
export * from "./LandingPage";
export * from "./LeadsForm";

export const COLORS = {
  WHITE: "#FFFFFF",
  GREY: "#888888",
  BLACK: "#000000",
  GREEN_HAZE: "#038348"
};

export const BOOKING_STATE = {
  NO_ACTION: "NO_ACTION",
  REQUIRES_DRIVER: "REQUIRES_DRIVER",
  REQUIRES_ACKNOWLEDGEMENT: "REQUIRES_ACKNOWLEDGEMENT",
  BOOKING_CANCELLED: "BOOKING_CANCELLED"
};

export const GTM_CONTAINER_ID = "GTM-NNN3KBK";
export const GA_TRACKING_ID = "UA-63397281-8";
export const GOOGLE_MAPS_KEY = window.env.googleMapsPublicKey;

export const API_ROOT = () => {
  switch (process.env.NODE_ENV) {
    case "production":
      return "/api";
    default:
      return `${window.location.protocol}//${window.location.host}/api`;
  }
};

export const STATUS_CODES = {
  ERROR_400: 400,
  ERROR_404: 404,
  ERROR_409: 409,
  ERROR_503: 503,
  ERROR_429: 429
};

export const API_ENDPOINTS = {
  AIRPORTS: `${API_ROOT()}/airports`,
  BOOKINGS_CSV: `${API_ROOT()}/bookings/csv`,
  COUNTRIES: `${API_ROOT()}/countries`,
  DRIVERS: `${API_ROOT()}/drivers`,
  EXTRAS: `${API_ROOT()}/extras`,
  FILTERS: `${API_ROOT()}/filters`,
  PAST_RIDES: `${API_ROOT()}/bookings/past`,
  RATES: `${API_ROOT()}/rates`,
  RATE_RULES: `${API_ROOT()}/rates-setup`,
  RATE_RULES_DAILY: `${API_ROOT()}/rate-rules/daily`,
  RATE_RULES_HOURLY: `${API_ROOT()}/rate-rules/hourly`,
  FIXED_ROUTES: `${API_ROOT()}/fixed-routes`,
  OPERATING_HOURS: `${API_ROOT()}/operating-hours`,
  RIDES: `${API_ROOT()}/bookings`,
  BOOKING_EVENT: `${API_ROOT()}/bookings/event`,
  STOP_SALES: `${API_ROOT()}/stop-sales`,
  GENIUS: `${API_ROOT()}/genius`,
  CAMPAIGNS: `${API_ROOT()}/campaigns`,
  AREA_OF_OPERATION: `${API_ROOT()}/area-of-operation`,
  SUPPLIER: `${API_ROOT()}/supplier`,
  SUPPLIER_LOCATION: `${API_ROOT()}/supplier-location`,
  SUPPLIER_LOCATIONS: `${API_ROOT()}/supplier-locations`,
  CAR_TYPES: `${API_ROOT()}/car-types`,
  SUPPLIER_METRICS: `${API_ROOT()}/metrics`,
  SUPPLIER_REVIEWS: `${API_ROOT()}/reviews`,
  REPORTS: {
    DRIVERS: {
      STATS: `${API_ROOT()}/reports/drivers/statistics`,
      STATS_AGGREGATED: `${API_ROOT()}/reports/drivers/statistics/aggregated`
    }
  },
  REPORTS_OVERVIEW: `${API_ROOT()}/reports/overview`,
  REPORTS_DRIVERS: `${API_ROOT()}/reports/drivers`,
  REPORTS_PREFERRED: `${API_ROOT()}/reports/preferred`,
  SUPPLY_PARTNER: `${API_ROOT()}/supplier/performance`,
  REPORT_RIDES: `${API_ROOT()}/reports/rides`,
  REPORT_RIDES_CSV: `${API_ROOT()}/reports/rides/csv`,
  AMENDMENTS_ACKNOWLEDGE: `${API_ROOT()}/bookings/amendments/acknowledge`,
  DISTANCE_RATES: `${API_ROOT()}/distance-rates`,
  FEATURE_TOGGLES: `${API_ROOT()}/feature-toggles`,
  SALESFORCE_LEADS_FORM: `${API_ROOT()}/leads`,
  LOCATION_AND_RATES: `${API_ROOT()}/location-and-rates`,
  PICKUP_INSTRUCTIONS: `${API_ROOT()}/pickup-instructions`
};

export const AVAILABLE_LOCALES = ["es-ES", "en-GB"];
export const DEFAULT_LOCALE = "en-GB";
export const AVAILABLE_LANGUAGES = ["en", "es"];
export const DEFAULT_LANGUAGE = "en";

export const SMS_STATUS = {
  NONE: "NONE",
  SENT: "SENT",
  SENDING: "SENDING"
};

export const VALIDATION_RULE = {
  TEL: "TEL",
  PASSWORD: "PASSWORD",
  PASSWORD_LENGTH: "PASSWORD_LENGTH",
  PASSWORD_CHARACTERS: "PASSWORD_CHARACTERS",
  PASSWORD_LOWER: "PASSWORD_LOWER",
  PASSWORD_UPPER: "PASSWORD_UPPER",
  BOOKING_ID: "BOOKING_ID",
  VERIFICATION_CODE: "VERIFICATION_CODE"
};

export const NAVBAR_LINKS = [
  {
    id: "bookings",
    path: PATHS.BOOKINGS,
    href: PATHS.BOOKINGS_RIDES,
    formattedMessage: {
      id: "navbar.bookings",
      defaultMessage: "Bookings"
    },
    testId: "navbar-link-bookings"
  },
  {
    id: "drivers",
    path: PATHS.DRIVERS,
    href: PATHS.DRIVERS,
    formattedMessage: {
      id: "navbar.drivers",
      defaultMessage: "Drivers"
    },
    testId: "navbar-link-drivers"
  },
  {
    id: "reports",
    path: PATHS.REPORTS,
    href: PATHS.REPORTS_OVERVIEW,
    formattedMessage: {
      id: "navbar.reports",
      defaultMessage: "Reports"
    },
    testId: "navbar-link-reports"
  },
  {
    id: "availability",
    path: PATHS.AVAILABILITY_DASHBOARD,
    href: PATHS.AVAILABILITY_DASHBOARD,
    formattedMessage: {
      id: "navbar.availability",
      defaultMessage: "Availability"
    },
    testId: "navbar-link-availability"
  },
  {
    id: "campaigns",
    path: PATHS.CAMPAIGNS,
    href: PATHS.CAMPAIGNS,
    formattedMessage: {
      id: "navbar.campaigns",
      defaultMessage: "Campaigns"
    },
    testId: "navbar-link-campaigns"
  },
  {
    id: "genius",
    path: PATHS.GENIUS,
    href: PATHS.GENIUS,
    formattedMessage: { id: "navbar.genius", defaultMessage: "Genius" },
    testId: "navbar-link-genius"
  },
  {
    id: "locations",
    path: PATHS.RATES,
    href: PATHS.RATES,
    formattedMessage: {
      id: "navbar.locationAndRates",
      defaultMessage: "Location & Rates"
    },
    testId: "navbar-link-location-and-rates"
  }
];

export const ALERT_TYPES = {
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning"
};

export const CSV_EXPORT_DISABLE_MODAL = "csv-export-dont-show-again";

export const RATES_FORM_LOCAL_STORAGE = "ratesForm";

export const ZENDESK_LINK = "https://taxisuppliers.zendesk.com";

export const BOOKINGS_TABS_OPTIONS = [
  { text: "Rides", formattedMessage: { id: "tabs.rides", defaultMessage: "Rides" }, value: "rides" },
  { text: "Past rides", formattedMessage: { id: "tabs.pastRides", defaultMessage: "Past rides" }, value: "past-rides" }
];

export const PREFERRED_STATUS_CYCLE_METRICS = {
  INCIDENT_RATE: "incident_rate",
  DRIVER_EVENTS: "driver_events",
  DECLINE_RATE: "decline_rate",
  AVG_SCORE: "avg_score"
};

export const DRIVER_REPORTS_TABS_OPTIONS = [
  {
    text: "Overview",
    formattedMessage: { id: "tabs.overview", defaultMessage: "Overview" },
    value: "overview"
  },
  {
    text: "Driver Analysis",
    formattedMessage: { id: "tabs.driver_analysis", defaultMessage: "Driver Analysis" },
    value: "driver_analysis"
  },
  {
    text: "Rides",
    formattedMessage: { id: "tabs.rides", defaultMessage: "Rides" },
    value: "rides"
  }
];

export const BOOKINGS_DATE_RANGE_OPTIONS = {
  rides: [
    { id: "PERIOD_ALL", text: "All", formattedMessage: { id: "filters.all", defaultMessage: "All" } },
    { id: "PERIOD_TODAY", text: "Today", formattedMessage: { id: "filters.today", defaultMessage: "Today" } },
    {
      id: "PERIOD_TOMORROW",
      text: "Tomorrow",
      formattedMessage: { id: "filters.tomorrow", defaultMessage: "Tomorrow" }
    },
    { id: "PERIOD_WEEK", text: "Week", formattedMessage: { id: "filters.week", defaultMessage: "Week" } },
    { id: "PERIOD_MONTH", text: "Month", formattedMessage: { id: "filters.month", defaultMessage: "Month" } }
  ],
  pastRides: [
    { id: "PERIOD_TODAY", text: "Today", formattedMessage: { id: "filters.today", defaultMessage: "Today" } },
    {
      id: "PERIOD_YESTERDAY",
      text: "Yesterday",
      formattedMessage: { id: "filters.yesterday", defaultMessage: "Yesterday" }
    },
    { id: "PERIOD_WEEK", text: "Week", formattedMessage: { id: "filters.week", defaultMessage: "Week" } },
    { id: "PERIOD_MONTH", text: "Month", formattedMessage: { id: "filters.month", defaultMessage: "Month" } }
  ]
};

export const INTL_EXPORT_NEW_BOOKINGS = {
  id: "filters.downloadCSV",
  defaultMessage: "Export new bookings"
};

export const INTL_EXPORT_NEW_BOOKINGS_DESCRIPTION = {
  id: "filters.downloadCSVDescription",
  defaultMessage: "Only bookings in the 'Acknowledge' state will be exported"
};

export const INTL_EXPORT_NEW_BOOKINGS_DONT_SHOW_AGAIN = {
  id: "filters.downloadCSVDontShowAgain",
  defaultMessage: "Don't show this message again"
};

export const BOOKINGS_TABS = {
  RIDES: "rides",
  PAST_RIDES: "past-rides"
};

export const DRIVER_REPORTS_TABS = {
  OVERVIEW: "overview",
  ANALYSIS: "driver_analysis",
  RIDES: "rides"
};

export const INTL_BOOKING_CONTROL_PANEL = {
  DOWNLOAD: { id: "bookingsControlPanel.download", defaultMessage: "Download" },
  FILTER: { id: "bookingsControlPanel.filter", defaultMessage: "Filter" },
  RIDES: { id: "bookingsControlPanel.rides", defaultMessage: "rides" }
};

export const DATE_RANGE_FILTERS = {
  MORE_LABEL: { id: "tab.more", defaultMessage: "More" }
};

export const REGEX = {
  ANY_NUMBER_OTHER_THAN_ZERO: /[1-9][0-9]*/
};
